import { SvgArrowStyled } from "./carousel_arrows.styles"

/*
    swiperClass - klasa ustawiona w swiperze dla nawigacji
    direction - left | right
*/
const CarouselArrows = ({ swiperClass, direction }) => {
    return (
        <>
            {
                direction === 'left' ?
                    <SvgArrowStyled width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={swiperClass}>
                        <path d="M35.75 61.2576C33.4295 62.5973 30.5705 62.5973 28.25 61.2576L8.53719 49.8764C6.21668 48.5366 4.78719 46.0607 4.78719 43.3812L4.78719 20.6188C4.78719 17.9393 6.21668 15.4634 8.53719 14.1236L28.25 2.74242C30.5705 1.40267 33.4295 1.40267 35.75 2.74242L55.4628 14.1236C57.7833 15.4634 59.2128 17.9393 59.2128 20.6188L59.2128 43.3812C59.2128 46.0607 57.7833 48.5366 55.4628 49.8764L35.75 61.2576Z" fill="white" className="white-fill" />
                        <path d="M35.75 61.2576C33.4295 62.5973 30.5705 62.5973 28.25 61.2576L8.53719 49.8764C6.21668 48.5366 4.78719 46.0607 4.78719 43.3812L4.78719 20.6188C4.78719 17.9393 6.21668 15.4634 8.53719 14.1236L28.25 2.74242C30.5705 1.40267 33.4295 1.40267 35.75 2.74242L55.4628 14.1236C57.7833 15.4634 59.2128 17.9393 59.2128 20.6188L59.2128 43.3812C59.2128 46.0607 57.7833 48.5366 55.4628 49.8764L35.75 61.2576Z" fill="white" className="white-fill" />
                        <path d="M35.75 61.2576C33.4295 62.5973 30.5705 62.5973 28.25 61.2576L8.53719 49.8764C6.21668 48.5366 4.78719 46.0607 4.78719 43.3812L4.78719 20.6188C4.78719 17.9393 6.21668 15.4634 8.53719 14.1236L28.25 2.74242C30.5705 1.40267 33.4295 1.40267 35.75 2.74242L55.4628 14.1236C57.7833 15.4634 59.2128 17.9393 59.2128 20.6188L59.2128 43.3812C59.2128 46.0607 57.7833 48.5366 55.4628 49.8764L35.75 61.2576Z" stroke="#1C174C" className="dark-stroke" />
                        <path d="M24 33.7321C22.6667 32.9623 22.6667 31.0377 24 30.2679L36 23.3397C37.3333 22.5699 39 23.5322 39 25.0718V38.9282C39 40.4678 37.3333 41.4301 36 40.6603L24 33.7321Z" fill="#1C174C" className="dark-fill" />
                    </SvgArrowStyled>
                    :
                    <SvgArrowStyled width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={swiperClass}>
                        <path d="M35.75 61.2576C33.4295 62.5973 30.5705 62.5973 28.25 61.2576L8.53719 49.8764C6.21668 48.5366 4.78719 46.0607 4.78719 43.3812L4.78719 20.6188C4.78719 17.9393 6.21668 15.4634 8.53719 14.1236L28.25 2.74242C30.5705 1.40267 33.4295 1.40267 35.75 2.74242L55.4628 14.1236C57.7833 15.4634 59.2128 17.9393 59.2128 20.6188L59.2128 43.3812C59.2128 46.0607 57.7833 48.5366 55.4628 49.8764L35.75 61.2576Z" fill="white" className="white-fill" />
                        <path d="M35.75 61.2576C33.4295 62.5973 30.5705 62.5973 28.25 61.2576L8.53719 49.8764C6.21668 48.5366 4.78719 46.0607 4.78719 43.3812L4.78719 20.6188C4.78719 17.9393 6.21668 15.4634 8.53719 14.1236L28.25 2.74242C30.5705 1.40267 33.4295 1.40267 35.75 2.74242L55.4628 14.1236C57.7833 15.4634 59.2128 17.9393 59.2128 20.6188L59.2128 43.3812C59.2128 46.0607 57.7833 48.5366 55.4628 49.8764L35.75 61.2576Z" fill="white" className="white-fill" />
                        <path d="M35.75 61.2576C33.4295 62.5973 30.5705 62.5973 28.25 61.2576L8.53719 49.8764C6.21668 48.5366 4.78719 46.0607 4.78719 43.3812L4.78719 20.6188C4.78719 17.9393 6.21668 15.4634 8.53719 14.1236L28.25 2.74242C30.5705 1.40267 33.4295 1.40267 35.75 2.74242L55.4628 14.1236C57.7833 15.4634 59.2128 17.9393 59.2128 20.6188L59.2128 43.3812C59.2128 46.0607 57.7833 48.5366 55.4628 49.8764L35.75 61.2576Z" stroke="#1C174C" className="dark-stroke" />
                        <path d="M42 30.2679C43.3333 31.0377 43.3333 32.9623 42 33.7321L30 40.6603C28.6667 41.4301 27 40.4678 27 38.9282L27 25.0718C27 23.5322 28.6667 22.5699 30 23.3397L42 30.2679Z" fill="#1C174C" className="dark-fill" />
                    </SvgArrowStyled>
            }
        </>
    )
}

export default CarouselArrows