import { SlotsIconsContainerStyled, SlotsIconStyled, SlotsRollStyled } from "./roll.styles";
import { useCallback, useRef, useState } from "react";

const Roll = ({ animate, duration, amount, initial_position }) => {
   const [width, setWidth] = useState(undefined)

   const onRefChange = useCallback((node) => {
      if (node) setWidth(node.offsetWidth)
   }, [])

   return (
      <SlotsRollStyled ref={onRefChange}>
         <div className="absolute left-0 top-0 w-full h-6 bg-gradient-to-b from-[#00000030] to-transparent bg-opacity-50 z-20"></div>
         <div className="absolute left-0 bottom-0 w-full h-6 bg-gradient-to-t from-[#00000030] to-transparent bg-opacity-50 z-20"></div>
         {width !== undefined && <SlotsIconsContainerStyled animate={animate} duration={duration} amount={amount * width} initial_position={initial_position * width}>
            <SlotsIconStyled src={'/images/minigames/roll.svg'} alt="roll" width={41} height={492} />
            <SlotsIconStyled src={'/images/minigames/roll.svg'} alt="roll" width={41} height={492} />
            <SlotsIconStyled src={'/images/minigames/roll.svg'} alt="roll" width={41} height={492} />
         </SlotsIconsContainerStyled>}
      </SlotsRollStyled>
   );
};

export default Roll;
