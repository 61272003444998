// import roll from '../../../images/minigames/roll.svg'
import { SlotsButtonStyled, SlotsContainerStyled, SlotsRollsStyled } from './slots.styles'
import { useRef, useState } from 'react'
import Roll from './roll/roll'
import Award from '../award/award'

const Slots = ({pause, resume}) => {
    const [animate, setAnimate] = useState(false)
    const [showAward, toggleAward] = useState(false)

    const startAnimation = () => {
        pause()
        setAnimate(true)
        setTimeout(() => {
            resume()
            toggleAward(true)
        }, 3200)
    }

    return (
        <>
            <SlotsContainerStyled className='mt-10 sm:mt-14 md:mt-0'>
                <SlotsRollsStyled>
                    <Roll animate={animate} duration={'1s'} amount={10} initial_position={0} />
                    <Roll animate={animate} duration={'2s'} amount={22} initial_position={1} />
                    <Roll animate={animate} duration={'3s'} amount={30} initial_position={2} />
                </SlotsRollsStyled>
                <SlotsButtonStyled onClick={startAnimation}>SPIN</SlotsButtonStyled>
            </SlotsContainerStyled>
            {showAward && <Award />}
        </>
    )
}

export default Slots