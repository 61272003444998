import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import box from "../public/images/box-path.png";
import { PaginationContainerStyled } from "./opinions/opinions.styles";
import HeroSlide from "./hero_slide/hero_slide";
import styled from "styled-components";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import "swiper/css/effect-fade";
import "swiper/css";

// nie przesyłasz żadnych propsów
export default function Hero() {
   const swiperRef = useRef();
   // najpierw ten hook on wyciąga całość
   const { t } = useTranslation("homepage");
   // tak wyciągasz zwykłe klucze
   // tak wyciągasz klucze które zwracają tablice do mapowania
   const contentArr = t("homepage.hero.content", { returnObjects: true });

   const pause = () => {
      swiperRef.current.swiper.autoplay.pause();
   };
   const resume = () => {
      swiperRef.current.swiper.autoplay.run();
   };

   return (
      <div className="w-full relative py-5 2xl:py-20 px-4 2xl:px-0">
         <Swiper
            ref={swiperRef}
            slidesPerView={'auto'}
            modules={[Navigation, Pagination, Autoplay]}
            navigation={{
               nextEl: ".next-slide-games",
               prevEl: ".prev-slide-games",
            }}
            loop
            autoplay={{
               delay: 10000,
            }}
            pause
            pagination={{
               clickable: true,
               el: ".custom-pagination-games",
            }}
            allowTouchMove={true}
            onInit={(swiper) => {
               swiper.params.navigation.nextEl = ".next-slide-games";
               swiper.params.navigation.prevEl = ".prev-slide-games";
               swiper.navigation.init();
               swiper.navigation.update();
            }}
            className="mb-4 md:mb-0 relative z-10"
            breakpoints={{
               767: {
                  allowTouchMove: false,
               },
            }}
         >
            <SwiperSlide>
               <HeroSlide heading={contentArr[0].heading} body={contentArr[0].body} game="wheel" pause={pause} resume={resume} />
            </SwiperSlide>
            <SwiperSlide>
               <HeroSlide heading={contentArr[1].heading} body={contentArr[1].body} game="memory" pause={pause} resume={resume} />
            </SwiperSlide>
            <SwiperSlide>
               <HeroSlide heading={contentArr[2].heading} body={contentArr[2].body} game="slots" pause={pause} resume={resume} />
            </SwiperSlide>
         </Swiper>
         <div className="relative z-10">
            <PaginationContainerStyled className="custom-pagination-games"></PaginationContainerStyled>
         </div>
         <div className="absolute left-0 top-0 w-full h-full z-0">
            <div className="container mx-auto relative h-full">
               <StyledImg>
                  <Image src={'/images/box-path.png'} width={720} height={700} alt="hero box" />
               </StyledImg>
            </div>
         </div>
      </div>
   );
}

const StyledImg = styled.div`
   position: absolute;
   width: 36rem;
   right: 0;
   top: 10%;

   @media (max-width: 1536px) {
      top: 5%;
   }

   @media (max-width: 1024px) {
      top: auto;
      bottom: 5%;
   }

   @media (max-width: 640px) {
      width: 90%;
      right: 5%;
   }
`;
