import { CardFaceStyled, MemoryCardContainerStyled } from "./memory_card.styles";
import Image from 'next/image'

const MemoryCard = ({ id, flipped, val, flipCard, blocked }) => {
   return (
      <MemoryCardContainerStyled flipped={flipped} onClick={() => !blocked && !flipped && flipCard(id)}>
         <CardFaceStyled>
            <Image src={'/images/minigames/star_dark.svg'} width={100} height={100} alt="Ember" />
         </CardFaceStyled>
         <CardFaceStyled color={val} className="front">
            <Image src={'/images/minigames/star_dark.svg'} width={100} height={100} alt="Ember" />
         </CardFaceStyled>
      </MemoryCardContainerStyled>
   );
};

export default MemoryCard;
