import React from "react";
import Tag from "../Tag";
import FunctionsSlider from "./FunctionsSlider";
import parse from "html-react-parser";
import { motion } from "framer-motion";

import { useTranslation } from "next-i18next";
import { useScroll } from "../useScroll";
import { scrollReveal } from "../animations";

export default function Services() {
   const { t } = useTranslation("homepage");
   const data = t("homepage.services", { returnObjects: true });
   const [element, controls] = useScroll();
   return (
      <motion.div variants={scrollReveal} animate={controls} initial="hidden" ref={element} className="py-10 lg:py-0 lg:mb-0">
         <Tag text={data.tag} />
         <h3>{parse(data.servicesDescription.heading)}</h3>
         <p>{parse(data.servicesDescription.paragraph)}</p>
         <FunctionsSlider functions={data.functions} />
      </motion.div>
   );
}
