import React from "react";
import Head from 'next/head'
import styled from "styled-components";
import ForWho from "../components/forWho/ForWho";
import OurWork from "../components/ourWork/OurWork";
import Opinions from "../components/opinions/opinions";
import Faq from "../components/faq/Faq";
import Services from "../components/Services/Services";
import Offer from "../components/offer/Offer";
import Hero from "../components/Hero";
import Contact from "../components/contact/Contact";
import { motion } from "framer-motion";
import { pageAnimation } from "../components/animations";
import { Element } from "react-scroll";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import CustomHead from "../components/head/head";

const lightBlue = "#F5F8FD";
const white = "#fff";

const Home = () => {
   const { t } = useTranslation('meta')

   return (
      <div>
         <CustomHead />
         <motion.div initial="hidden" animate="show" exit="exit">
            <motion.div variants={pageAnimation} initial="hidden" animate="show" exit="exit">
               <Section theme={{ bgColor: lightBlue }} className="top-margin">
                  <Hero />
               </Section>
               {/* <Loader /> */}
               <Element name="oferta">
                  <Section theme={{ bgColor: white }}>
                     <div className="container mx-auto  py:12 lg:py-24">
                        <Offer />
                     </div>
                  </Section>
               </Element>
               <Section>
                  <OurWork />
               </Section>
               <Element name="funkcje">
                  <Section theme={{ bgColor: white }}>
                     <div className="container mx-auto  py:12 lg:py-24">
                        <Services />
                     </div>
                  </Section>
               </Element>
               <Section>
                  <Opinions />
               </Section>
               <Element name="dlaKogo">
                  <Section theme={{ bgColor: white }}>
                     <div className="container mx-auto  py:12 lg:py-24">
                        <ForWho />
                     </div>
                  </Section>
               </Element>
               <Section theme={{ bgColor: lightBlue }}>
                  <div className="container mx-auto  py:12 lg:py-24">
                     <Faq />
                  </div>
               </Section>
               <Element name="kontakt">
                  <Section theme={{ bgColor: white }}>
                     <div className="container mx-auto  py:12 lg:py-24">
                        <Contact />
                     </div>
                  </Section>
               </Element>
            </motion.div>
         </motion.div>
      </div>
   );
};

export default Home;

export async function getServerSideProps({ locale }) {
   return {
      props: {
         ...(await serverSideTranslations(locale, ["homepage", "meta"])),
      },
   };
}

const Section = styled.div`
            position: relative;
            overflow: hidden;
            width: 100%;
            background-color: ${(props) => (props.theme.bgColor !== "" ? props.theme.bgColor : null)};
`;

