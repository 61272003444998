import styled from "styled-components";

export const MemoryContainerStyled = styled.div`
    width: 600px;
    padding: 50px 0;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    perspective: 1000px;

    @media (max-width: 640px){
        width: 100%;
    }
`