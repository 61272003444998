import styled from "styled-components";

export const Label = styled.div`
   border: 1px solid #1c174c;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 12px;
   margin-bottom: 12px;
   padding: 24px;
   cursor: pointer;
   img {
      transition: all 0.2s linear;
   }
   h4 {
      margin: 0;
      font-size: 20px;
      font-weight: 800;
   }
   .rotated {
      transform: rotate(119deg);
      transition: all 0.2s linear;
   }
   .show {
      height: auto;
      max-height: 9999px;
      transition: all 0.5s cubic-bezier(1, 0, 1, 0);
   }
`;

export const Comment = styled.div`
   height: auto;
   max-height: 0px;
   overflow: hidden;
   transition: all 0.5s cubic-bezier(0, 1, 0, 1);
`;
