import React, { useState } from "react";
import Button from "../Button";
import DropDown from "./DropDown";
import Tag from "../Tag";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useScroll } from "../useScroll";
import { scrollReveal } from "../animations";
import parse from "html-react-parser";
import { FaqSection } from "./faq.styles";
import { useTranslation } from "next-i18next";
export default function Faq() {
   const { t } = useTranslation("homepage");
   const data = t("homepage.faq", { returnObjects: true });

   const [element, controls] = useScroll();
   const [isVisible, setIsVisible] = useState(false);
   const toggleDropDown = () => {
      setIsVisible(!isVisible);
   };
   return (
      <FaqSection variants={scrollReveal} animate={controls} initial="hidden" ref={element} className="py-10 lg:py-0 lg:mb-0">
         <Tag text={data.tag}></Tag>
         <h3>{parse(data.faqkDescription.heading)}</h3>
         <p>{parse(data.faqkDescription.paragraph)}</p>

         <div className={`drop-down ${isVisible ? "active" : "closed"}`}>
            <DropDown data={data.faqs} />
         </div>

         <div className="block lg:hidden mb-8" onClick={() => toggleDropDown()}>
            <Button text={"Pokaż sekcje FAQ"} />
         </div>
      </FaqSection>
   );
}
