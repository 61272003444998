import React from "react";
import Tag from "../Tag";
import EventsDesc from "./EventsDesc";
import Button from "../Button";
import Events from "./Events";
import { motion } from "framer-motion";
import { useScroll } from "../useScroll";
import { scrollReveal } from "../animations";
import { useTranslation } from "next-i18next";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
export default function Offer({ offerData }) {
   const [element, controls] = useScroll();
   // najpierw ten hook on wyciąga całość
   const { t } = useTranslation("homepage");
   const contentArr = t("homepage.offer", { returnObjects: true });
   console.log(contentArr);
   return (
      <motion.div className="grid grid-cols-1 lg:grid-cols-12 gap-4 lg:gap-24 py-10 lg:py-0" variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
         <div className="lg:col-span-7 order-last lg:order-first">
            <Events events={contentArr.offerEventBoxes} />
         </div>

         <div className="lg:col-span-5">
            <Tag text={contentArr.tag} />
            <EventsDesc data={contentArr.offerDescription} />
         </div>
      </motion.div>
   );
}
