import React, { useState } from "react";
import styled from "styled-components";
import event1 from "../../public/images/event1.png";
import event2 from "../../public/images/event2.png";
import event3 from "../../public/images/event3.png";
import event4 from "../../public/images/event4.png";
import Image from 'next/image'

import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";
import { fade } from "../animations";
import { PaginationContainerStyled } from "../swiper.styles";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Grid, Navigation, Pagination } from "swiper";
import { Card, ColoredBox, HoveredColoredBox } from "./events.styles";
export default function Events({ events }) {
   const [currentClicked, setCurrentClicked] = useState(null);
   const handleClick = (index) => {
      setCurrentClicked(index);
   };
   const setImage = (src) => {
      switch (src) {
         case "event1":
            return event1;
         case "event2":
            return event2;
         case "event3":
            return event3;
         case "event4":
            return event4;
         default:
            return event1;
      }
   };
   return (
      <>
         <div className="">
            <Swiper
               slidesPerView={'auto'}
               grid={{
                  rows: 1,
                  fill: "row",
               }}
               loop
               autoplay={{
                  delay: 10000,
               }}
               spaceBetween={5}
               modules={[Grid, Pagination]}
               breakpoints={{
                  1024: {
                     slidesPerView: 2,
                     spaceBetween: 10,
                     grid: { rows: 2 },
                  },
               }}
               // onInit={(swiper) => {
               //    swiper.init();
               //    swiper.update();
               // }}
               pagination={{
                  clickable: true,
                  el: ".custom-pagination2",
               }}
            >
               {events.map((event, index) => {
                  return (
                     <SwiperSlide className="p-1" key={index}>
                        <div onClick={() => handleClick(index)}>
                           <Card flipped={currentClicked !== index ? false : true}>
                              <ColoredBox className="flex flex-col gap-8 items-center justify-center" theme={{ bgColor: event.color }}>
                                 <Image src={setImage(event.imgSrc)} alt="" />
                                 <h3>{event.text}</h3>
                              </ColoredBox>
                              <HoveredColoredBox theme={{ bgColor: event.color }}>
                                 <h3 className="text-center text-xl xl:text-2xl">{event.back.heading}</h3>
                                 {parse(event.back.list)}
                              </HoveredColoredBox>
                           </Card>
                        </div>
                     </SwiperSlide>
                  );
               })}
            </Swiper>
            <PaginationContainerStyled className="custom-pagination2 mt-4 mb-8"></PaginationContainerStyled>
         </div>
      </>
   );
}
