import styled from "styled-components";
import { motion } from "framer-motion";
export const FaqSection = styled(motion.div)`
   .drop-down.active {
      height: 100%;
   }
   .drop-down.closed {
      @media only screen and (max-width: 1024px) {
         display: none;
      }
   }
   .drop-down {
      @media only screen and (max-width: 1024px) {
         height: 0;
      }
   }
`;
