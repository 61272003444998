import React from "react";
import Tag from "../Tag";
import FormComponent from "./FormComponent";
import { motion } from "framer-motion";
import { useScroll } from "../useScroll";
import { scrollReveal } from "../animations";

import { useTranslation } from "next-i18next";
import parse from "html-react-parser";
export default function Contact() {
   const { t } = useTranslation("homepage");
   const data = t("homepage.contact", { returnObjects: true });
   const [element, controls] = useScroll();
   return (
      <motion.div className="grid grid-cols-1 lg:grid-cols-12 gap-4 lg:gap-24 items-center py-10 lg:py-0 lg:mb-0" variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
         <div className="lg:col-span-7  ">
            <Tag text={data.tag} />
            <h3>{parse(data.contactDescription.heading)}</h3>
            <p> {parse(data.contactDescription.multipleParagraphs[0])}</p>

            <p> {parse(data.contactDescription.multipleParagraphs[1])}</p>
         </div>

         <div className="lg:col-span-5">
            <FormComponent data={data.formContent} />
         </div>
      </motion.div>
   );
}
