import styled from "styled-components";
import listStyling from "../../public/images/list-styling.png";
export const Card = styled.div`
   background-color: ${(props) => props.theme.bgColor};
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 16px;
   padding: 74px 24px;
   min-height: 670px;
   height: 100%;
   color: white;
   p {
      margin-bottom: 16px;
      line-height: 22px;
   }
   ul {
      position: relative;

      margin-left: 0;
      padding-left: 1.2em;
   }
   li {
      list-style: none;
      margin-bottom: 16px;
      line-height: 22px;
   }

   ul li:before {
      content: url(/images/list-styling.png);
      position: absolute;
      left: 0;
      margin-top: 8px;
   }
`;
export const Separator = styled.div`
   border-bottom: 1px solid white;
   margin: 32px 0;
`;
export const CardsContainer = styled.div``;
