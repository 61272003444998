import styled from "styled-components";
import { motion } from "framer-motion";
export const Card = styled(motion.div)`
   transform: scale(1);
   transform-style: preserve-3d;
   position: relative;
   transition: transform 0.5s;
   transform: ${(props) => (props.flipped ? "rotateY(180deg)" : "rotateY(0deg)")};
   cursor: pointer;
   min-height: 270px;

   @media (max-width: 1280px){
      min-height: 340px;
   }
   @media (max-width: 1024px){
      min-height: 270px;
   }
`;

export const ColoredBox = styled(motion.div)`
   background-color: ${(props) => props.theme.bgColor};
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 16px;
   /* height: 270px; */
   font-weight: 500;
   font-size: 32px;
   color: white;
   width: 100%;
   height: 100%;
   position: absolute;
   backface-visibility: hidden;
   h3 {
      margin: 0;
   }
`;
export const HoveredColoredBox = styled(motion.div)`
   background-color: white;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 16px;
   border: 1px solid ${(props) => props.theme.bgColor};
   font-weight: 500;
   padding: 20px;
   font-size: 32px;
   width: 100%;
   height: 100%;
   position: absolute;
   transform: rotateY(180deg);
   backface-visibility: hidden;
   li {
      font-size: 14px;
   }
   color: ${(props) => props.theme.bgColor};
`;
