import React from "react";
import styled from "styled-components";

import Tag from "./../Tag";
import { motion } from "framer-motion";
import { useScroll } from "../useScroll";
import { scrollReveal } from "../animations";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";

import { useTranslation } from "next-i18next";
import { PaginationContainerStyled } from "../swiper.styles";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Card, Separator } from "./for-who.styles";
import { Grid, Navigation, Pagination } from "swiper";

const ForWho = () => {
   const { t } = useTranslation("homepage");
   const data = t("homepage.forWho", { returnObjects: true });
   const [element, controls] = useScroll();
   return (
      <motion.div variants={scrollReveal} animate={controls} initial="hidden" ref={element} className="py-10 lg:py-0">
         <Tag text={data.tag} />
         <h3>{parse(data.forWhoDescription.heading)}</h3>
         <p>{parse(data.forWhoDescription.paragraph)}</p>
         <div className="">
            <Swiper
               slidesPerView={"auto"}
               grid={{
                  rows: 1,
                  fill: "row",
               }}
               autoplay={{
                  delay: 10000,
               }}
               spaceBetween={5}
               modules={[Grid, Pagination]}
               breakpoints={{
                  1024: {
                     slidesPerView: 3,
                     spaceBetween: 10,
                     grid: { rows: 1 },
                  },
               }}
               pagination={{
                  clickable: true,
                  el: ".custom-pagination5",
               }}
            >
               {data.cards.map((card, index) => {
                  return (
                     <SwiperSlide style={{ height: "auto" }} className="h-auto" key={index}>
                        <Card key={card.heading} index={card.heading} theme={{ bgColor: card.color }}>
                           <h3 className="text-right">{card.heading}</h3>
                           <Separator></Separator>
                           <p>{card.body}</p>
                           <ul>
                              {card.list.map((li) => (
                                 <li key={li.listItem}>{li.listItem}</li>
                              ))}
                           </ul>
                        </Card>
                     </SwiperSlide>
                  );
               })}
            </Swiper>
            <PaginationContainerStyled className="custom-pagination5 mt-4 mb-8"></PaginationContainerStyled>
         </div>
      </motion.div>
   );
};

export default ForWho;
