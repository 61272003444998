import styled from "styled-components";

export const MinigamesContainerStyled = styled.div`
  width: 720px;
  height: 540px;
  position: relative;

  @media (max-width: 1280px){
    max-width: 50vw;
  }
  @media (max-width: 1024px){
    max-width: 100%;
    height: auto;
    margin-top: 3rem;
  }

  @media (max-width: 767px){
    margin-top: 0;
  }
`;

export const ContainerInnerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
`

export const BoxStyled = styled.img`
  position: absolute;
  right: -5%;
  top: -50%;
  transform: translateY(50%);
  z-index: 0;
  width: 80%;
`