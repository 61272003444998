import parse from "html-react-parser";
import MinigamesContainer from "../minigames/container/container";
import CarouselArrows from "../carousel_arrows/carousel_arrows";

const HeroSlide = ({ heading, body, game, pause, resume }) => {
   return (
      <div className="container mx-auto">
         <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 lg:gap-24">
            <div className="lg:col-span-5 flex flex-col justify-center">
               <div className="flex items-center">
                  <div>
                     <h3 className="mb-4">{parse(heading)}</h3>
                     <p className="mb-4"> {body}</p>
                  </div>
               </div>
               <div className="md:flex gap-2 hidden">
                  <CarouselArrows direction="left" swiperClass={'prev-slide-games'} />
                  <CarouselArrows direction="right" swiperClass={'next-slide-games'} />
               </div>
            </div>
            <MinigamesContainer type={game} pause={pause} resume={resume} />
         </div>
      </div>
   );
};

export default HeroSlide;