import { Suspense, useEffect, useRef, useState } from "react";
import Tag from "../Tag";
import { useScroll } from "../useScroll";
import { scrollReveal } from "../animations";
import { OpinionAuthorStyled, OpinionCardStyled, OpinionWrapperStyled, PaginationContainerStyled } from "./opinions.styles";
import parse from "html-react-parser";

import { useTranslation } from "next-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

import "swiper/css/pagination";
import CarouselArrows from "../carousel_arrows/carousel_arrows";

const Opinions = () => {
   const { t } = useTranslation("homepage");
   const data = t("homepage.opinions", { returnObjects: true });
   const [element, controls] = useScroll();
   const prevRef = useRef(null);
   const nextRef = useRef(null);

   const [loaded, setLoaded] = useState(false);

   const loadSlides = () => {
      return data.opinions.map((opinion, index) => {
         return (
            <SwiperSlide key={opinion.company}>
               <OpinionCardStyled className="flex flex-col justify-between" theme={index % 2 !== 0 ? { color: "#1C174C", text: "white" } : { color: "white", text: "#1C174C" }}>
                  <div className="flex-1 flex items-center">
                     <div>{opinion.body}</div>
                  </div>
                  <div className="flex flex-col gap-4 text-right justify-between">
                     <OpinionAuthorStyled>{opinion.author}</OpinionAuthorStyled>
                     <div> {opinion.company}</div>
                  </div>
               </OpinionCardStyled>
            </SwiperSlide>
         );
      });
   };

   useEffect(() => setLoaded(true), []);

   return (
      <OpinionWrapperStyled variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
         <div className="container mx-auto ">
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-10 lg:gap-24 ">
               <div className="lg:col-span-5 ">
                  <div className="absolute-center">
                     <Tag text={parse(data.tag)} />
                     <h3>{parse(data.opinionsDescription.heading)}</h3>
                     <p>{data.opinionsDescription.paragraph}</p>
                     <div className="md:flex gap-2 hidden">
                        <CarouselArrows direction="left" swiperClass={"prev-slide-opinions"} />
                        <CarouselArrows direction="right" swiperClass={"next-slide-opinions"} />
                     </div>
                  </div>
               </div>

               <div className="lg:col-span-7 flex items-center">
                  <Swiper
                     slidesPerView={1}
                     spaceBetween={20}
                     loop
                     autoplay={{
                        delay: 10000,
                     }}
                     modules={[Navigation, Pagination]}
                     breakpoints={{
                        767: {
                           slidesPerView: 2,
                           spaceBetween: 50,
                        },
                     }}
                     pagination={{
                        clickable: true,
                        el: ".custom-pagination",
                     }}
                     navigation={{
                        nextEl: ".next-slide-opinions",
                        prevEl: ".prev-slide-opinions",
                     }}
                     onInit={(swiper) => {
                        swiper.params.navigation.nextEl = ".next-slide-opinions";
                        swiper.params.navigation.prevEl = ".prev-slide-opinions";
                        swiper.navigation.init();
                        swiper.navigation.update();
                     }}
                  >
                     {loaded && loadSlides()}
                  </Swiper>
               </div>
               <PaginationContainerStyled className="custom-pagination"></PaginationContainerStyled>
            </div>
         </div>
      </OpinionWrapperStyled>
   );
};

export default Opinions;
