import Image from 'next/image'
import { ArrowStyled, SpinButtonStyled, WheelOfFortuneContainerStyled, WheelStyled } from "./wheel_of_fortune.styles";
import wheel from "../../../public/images/minigames/wheel_3.svg";
import arrow from "../../../public/images/minigames/location-arrow-solid.svg";
import { useState } from "react";
import Award from "../award/award";

const WheelOfFortune = ({ pause, resume }) => {
   const [animate, setAnimate] = useState(false);
   const [awardVisible, setAwardVisible] = useState(false);

   const spinWheel = () => {
      setAnimate(true);
      pause();
      setTimeout(() => {
         setAwardVisible(true);
         resume();
      }, 5300);
   };

   return (
      <>
         <WheelOfFortuneContainerStyled>
            <WheelStyled animated={animate}>
               <Image src={'/images/minigames/wheel_3.svg'} layout="fill" alt="" />
            </WheelStyled>
            <SpinButtonStyled onClick={spinWheel}>
               <div>
                  <p>SPIN</p>
               </div>
               <ArrowStyled>
                  <Image src={'/images/minigames/location-arrow-solid.svg'} layout="fill" alt="arrow" />
               </ArrowStyled>
            </SpinButtonStyled>
         </WheelOfFortuneContainerStyled>
         {awardVisible && <Award />}
      </>
   );
};

export default WheelOfFortune;
