import styled from "styled-components";
import bgPath from "../../public/images/box-path.png";
import { motion } from "framer-motion";

export const OpinionWrapperStyled = styled(motion.div)`
   position: relative;

   ::before {
      position: absolute;
      content: "";
      z-index: -1;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 380px;
      background-color: #f5f8fd;
      @media only screen and (max-width: 1024px) {
         background: none;
      }
   }
   @media only screen and (max-width: 1024px) {
      background-color: #f5f8fd;
      padding: 40px 0 40px 0;
   }
`;

export const OpinionAuthorStyled = styled.div`
   font-weight: 800;
   font-size: 20px;
`;
export const OpinionContainerStyled = styled.div`
   background-image: ${`url(${bgPath})`};
   background-repeat: no-repeat;
   height: 700px;
   /* background-size: contain; */
   background-position: center right;
   background-position: 80px center;
   background-size: 88%;
   @media only screen and (max-width: 1024px) {
      background-image: none;
      height: auto;
   }
`;
export const OpinionCardStyled = styled.div`
   height: 500px;
   padding: 24px 24px 24px 24px;
   border: 1px solid #1c174c;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 24px;
   color: ${(props) => props.theme.text};
   background-color: ${(props) => props.theme.color};

   @media (max-width: 768px) {
      padding: 30px;
      height: 100%;
   }
`;
export const OpinionSelectBoxStyled = styled.div`
   width: 18px;
   height: 18px;
   background-color: ${(props) => props.theme.bgColor};
   border-radius: 4px;
   border: 1px solid darkBlue;
   &:hover,
   &:focus {
      background-color: darkBlue;
   }
`;

export const PaginationContainerStyled = styled.div`
   width: 100%;
   justify-content: center;
   display: none;

   @media (max-width: 768px) {
      display: flex;
   }
`;
