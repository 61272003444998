import styled from "styled-components";
import { motion } from "framer-motion";
export const ContentWraper = styled.div`
   height: 100%;
   width: 88%;
   margin-left: 11%;
   height: 700px;
   border-radius: 24px;
   display: flex;
   align-items: center;

   & img{
      width: 100%;
      border-radius: 24px;
   }

   @media only screen and (max-width: 1024px) {
      height: auto;
      background-size: cover;
      width: 100%;
      margin-left: 0;
   }
`;
export const Wrapper = styled(motion.div)`
   position: relative;
   ::before {
      position: absolute;
      content: "";
      z-index: -1;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 380px;
      background-color: #f5f8fd;
      @media only screen and (max-width: 1024px) {
         background: none;
      }
   }
   @media only screen and (max-width: 1024px) {
      background-color: #f5f8fd;
      padding: 40px 0 40px 0;
   }
`;
export const Container = styled.div`
   background-image: url("/images/box-path-mirrored.png");
   background-repeat: no-repeat;
   height: 700px;
   /* background-size: contain; */
   background-position: center right;
   background-position: 0px center;
   background-size: 88%;
   @media only screen and (max-width: 1024px) {
      background-image: none;
      height: auto;
   }
`;
