import React, { useState } from "react";
import styled from "styled-components";
import Image from "next/image";
import arrow from "../../public/images/drop-down-arrow-dark.png";

import { Label, Comment } from "./dropdown.styles";
export default function DropDown({ data }) {
   const [selected, setSelected] = useState(null);
   const toggle = (index) => {
      if (selected === index) {
         return setSelected(null);
      }
      setSelected(index);
   };
   return (
      <>
         {data.map((obj, index) => {
            return (
               <Label key={index} onClick={() => toggle(index)}>
                  <div className="flex justify-between items-center ">
                     <div>
                        <h4>{obj.question}</h4>
                     </div>
                     <div className="flex-shrink-0 w-5">
                        <Image src={'/images/drop-down-arrow-dark.png'} width={18} height={18}  className={ selected === index ? "rotated " : ''} alt="arrow" />
                     </div>
                  </div>

                  <Comment className={selected === index ? "show " : null}>{obj.body}</Comment>
               </Label>
            );
         })}
      </>
   );
}
