import React, { useRef } from "react";
import Image from "next/image";
//libs
import parse from "html-react-parser";
import { useTranslation } from "next-i18next";
//imgs
import CarouselArrows from "../carousel_arrows/carousel_arrows";
//comps
import Tag from "../Tag";
import { Swiper, SwiperSlide } from "swiper/react";
//hooks
import { useScroll } from "../useScroll";
//anims
import { scrollReveal } from "../animations";
//styles
import { ContentWraper, Wrapper, Container } from "./our-work.styles";
//swiper
import { Grid, Navigation, Pagination } from "swiper";
import { PaginationContainerStyled } from "../swiper.styles";

import allstar from '../../public/images/projects/allstar.jpg'
import pepper from '../../public/images/projects/pepper.jpg'
import schweppes from '../../public/images/projects/schweppes.jpg'

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

export default function OurWork() {
   const { t } = useTranslation("homepage");

   const data = t("homepage.ourWork", { returnObjects: true });

   const [element, controls] = useScroll();
   return (
      <Wrapper variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
         <div className="container mx-auto ">
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 lg:gap-8 xl:gap-24 ">
               <div className="lg:col-span-7 order-last lg:order-first">
                  <Container className="">
                     <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={20}
                        loop
                        autoplay={{
                           delay: 10000,
                        }}
                        modules={[Navigation, Pagination]}
                        navigation={{
                           prevEl: '.prev-slide-work',
                           nextEl: '.next-slide-work',
                        }}
                        onInit={(swiper) => {
                           swiper.params.navigation.prevEl = '.prev-slide-work'
                           swiper.params.navigation.nextEl = '.next-slide-work'
                           swiper.navigation.init();
                           swiper.navigation.update();
                        }}
                        pagination={{
                           clickable: true,
                           el: ".custom-pagination3",
                        }}
                     >
                        <SwiperSlide>
                           <ContentWraper>
                              <a href="https://drpepper.pl/" target="_blank" rel="noreferrer">
                                 <Image src={pepper} alt="All star" />
                              </a>
                           </ContentWraper>
                        </SwiperSlide>
                        <SwiperSlide>
                           <ContentWraper>
                              <a href="https://allstar.ple.gg/" target="_blank" rel="noreferrer">
                                 <Image src={allstar} alt="All star" />
                              </a>
                           </ContentWraper>
                        </SwiperSlide>
                        <SwiperSlide>
                           <ContentWraper>
                              <a href="https://schweppespolska.pl/" target="_blank" rel="noreferrer">
                                 <Image src={schweppes} alt="All star" />
                              </a>
                           </ContentWraper>
                        </SwiperSlide>
                     </Swiper>
                     <PaginationContainerStyled className="custom-pagination3 mt-4  "></PaginationContainerStyled>
                  </Container>
               </div>

               <div className="lg:col-span-5 flex items-center">
                  <div >
                     <Tag text={data.tag} />
                     <h3>{parse(data.ourWorkDescription.heading)}</h3>
                     <p>{parse(data.ourWorkDescription.paragraph)}</p>
                     <div className=" gap-2 hidden lg:flex">
                        <CarouselArrows direction="left" swiperClass={"prev-slide-work"} />
                        <CarouselArrows direction="right" swiperClass={"next-slide-work"} />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Wrapper>
   );
}
