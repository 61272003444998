import styled from "styled-components";

export const MemoryCardContainerStyled = styled.div`
   width: calc(33% - 30px);
   aspect-ratio: 1;
   margin: 5px;
   position: relative;
   transform: scale(1);
   transform-style: preserve-3d;
   transition: transform 0.5s;

   @media (max-width: 640px) {
      width: calc(33% - 10px);
   }

   @media (max-width: 400px) {
      width: calc(30%);
   }
   /* &:active{
        transform: scale(0.97);
        transition: transform 0.2s;
    } */

   transform: ${(props) => (props.flipped ? "rotateY(180deg)" : "rotateY(0deg)")};
`;

export const CardFaceStyled = styled.div`
   width: 100%;
   height: 100%;
   padding: 20px;
   position: absolute;
   border-radius: 5px;
   backface-visibility: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
   box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

   background: ${(props) => (props.color ? props.color : "#a27ceb")};
   cursor: pointer;

   transition: opacity 0.5s;
   :hover {
      opacity: 0.9;
      transition: opacity 0.5s;
   }
   &.front {
      transform: rotateY(180deg);
   }

   & > div {
      width: 100px;
      height: 100px;
   }

   & img {
      width: 100px;
   }
`;
