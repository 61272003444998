import styled from 'styled-components'

export const SvgArrowStyled = styled.svg`
    cursor: pointer;

    &:hover{
    .white-fill{
        fill: rgb(28,23,76);
        transition: all 0.5s;
    }
    .dark-stroke{
        stroke: #FF5670;
        transition: all 0.5s;
        
    }
    .dark-fill{
        fill: #FF5670;
        transition: all 0.5s;
    }
    }
`