import React, { useState, useRef } from "react";
import styled from "styled-components";
// import parse from "html-react-parser";

import CarouselArrows from "../carousel_arrows/carousel_arrows";
import { Swiper, SwiperSlide } from "swiper/react";
import parse from "html-react-parser";
import { PaginationContainerStyled } from "../swiper.styles";
import Image from "next/image";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Grid, Navigation, Pagination } from "swiper";

import func1 from "../../public/images/functions-images/func1.png";
import func2 from "../../public/images/functions-images/func2.png";
import func3 from "../../public/images/functions-images/func3.png";
import func4 from "../../public/images/functions-images/func4.png";
import func5 from "../../public/images/functions-images/func5.png";
import func6 from "../../public/images/functions-images/func6.png";
import func7 from "../../public/images/functions-images/func7.png";
import func8 from "../../public/images/functions-images/func8.png";
import func9 from "../../public/images/functions-images/func9.png";

export default function FunctionsSlider({ functions }) {

   const setImage = (src) => {
      switch (src) {
         case "func1":
            return func1;
         case "func2":
            return func2;
         case "func3":
            return func3;
         case "func4":
            return func4;
         case "func5":
            return func5;
         case "func6":
            return func6;
         case "func7":
            return func7;
         case "func8":
            return func8;
         case "func9":
            return func9;
         default:
            return func1;
      }
   };
   return (
      <div>
         <div className=" gap-2 hidden lg:flex mb-4">
            <CarouselArrows direction="left" swiperClass={"prev-slide-features"} />
            <CarouselArrows direction="right" swiperClass={"next-slide-features"} />
         </div>
         <div className=" flex items-center">
            <Swiper
               slidesPerView={'auto'}
               spaceBetween={5}
               modules={[Grid, Pagination, Navigation]}
               loop
               autoplay={{
                  delay: 10000,
               }}
               breakpoints={{
                  1024: {
                     slidesPerView: 3,
                     spaceBetween: 10,
                  },
               }}
               navigation={{
                  prevEl: '.prev-slide-features',
                  nextEl: '.next-slide-features',
               }}
               onInit={(swiper) => {
                  swiper.params.navigation.prevEl = '.prev-slide-features'
                  swiper.params.navigation.nextEl = '.next-slide-features'
                  swiper.navigation.init();
                  swiper.navigation.update();
               }}
               // onInit={(swiper) => {
               //    swiper.init();
               //    swiper.update();
               // }}
               pagination={{
                  clickable: true,
                  el: ".custom-pagination4",
               }}
            >
               {functions.length > 0 && functions.map((func, index) => {
                  return (
                     <SwiperSlide key={index}>
                        <FunctionBox key={func.heading} theme={{ color: func.color }} className="flex flex-col items-center justify-center gap-4 text-center">
                           <Image src={setImage(func.imgSrc)} alt={func.heading} />
                           <h3>{func.heading}</h3>
                           <p>{func.body}</p>
                        </FunctionBox>
                     </SwiperSlide>
                  );
               })}
            </Swiper>
         </div>
         <div>
            <PaginationContainerStyled className="custom-pagination4 mt-4 mb-8 "></PaginationContainerStyled>
         </div>
      </div>
   );
}
const FunctionBox = styled.div`
   border: 1px solid ${(props) => props.theme.color};
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 16px;
   color: ${(props) => props.theme.color};
   padding: 50px 12px;
`;
